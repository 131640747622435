const domains = ['shalar.net',
    'bloggersxmi.com',
    'vipepe.com',
    'kekita.com',
    'hostlaba.com',
    'taphear.com',
    'dqnwara.com',
    'geekforex.com',
    'ciplafe.com',
    '001.igg.biz',
    '027168.com',
    '0815.ru',
    '0815.ry',
    '0815.su',
    '0845.ru',
    '0box.eu',
    '0clickemail.com',
    '0-mail.com',
    '0u.ro',
    '0v.ro',
    '0w.ro',
    '0wnd.net',
    '0wnd.org',
    '0x00.name',
    '0x207.info',
    '1000rebates.stream',
    '100likers.com',
    '10host.top',
    '10mail.com',
    '10mail.org',
    '10minut.com.pl',
    '10minutemail.be',
    '10minutemail.cf',
    '10minutemail.co.uk',
    '10minutemail.co.za',
    '10minutemail.com',
    '10minutemail.de',
    '10minutemail.ga',
    '10minutemail.gq',
    '10minutemail.info',
    '10minutemail.ml',
    '10minutemail.net',
    '10minutemail.nl',
    '10minutemail.org',
    '10minutemail.ru',
    '10minutemail.us',
    '10minutemailbox.com',
    '10minutenemail.de',
    '10minutesmail.com',
    '10minutesmail.fr',
    '10minutesmail.net',
    '10minutesmail.ru',
    '10vpn.info',
    '10x.es',
    '10x9.com',
    '11top.xyz',
    '123-m.com',
    '126.com',
    '12hosting.net',
    '12houremail.com',
    '12minutemail.com',
    '12minutemail.net',
    '12storage.com',
    '139.com',
    '140unichars.com',
    '147.cl',
    '14n.co.uk',
    '15qm.com',
    '15qm-mail.red',
    '163.com',
    '1-8.biz',
    '188.com',
    '1ce.us',
    '1chuan.com',
    '1clck2.com',
    '1fsdfdsfsdf.tk',
    '1mail.ml',
    '1pad.de',
    '1rentcar.top',
    '1s.fr',
    '1ss.noip.me',
    '1st-forms.com',
    '1to1mail.org',
    '1usemail.com',
    '1zhuan.com',
    '2.emailfake.ml',
    '2000rebates.stream',
    '2012-2016.ru',
    '2014mail.ru',
    '20email.eu',
    '20email.it',
    '20mail.eu',
    '20mail.in',
    '20mail.it',
    '20minute.email',
    '20minutemail.com',
    '2120001.net',
    '21cn.com',
    '225522.ml',
    '24hourmail.com',
    '24hourmail.net',
    '291.usa.cc',
    '2ch.coms.hk',
    '2ch.orgs.hk',
    '2-ch.space',
    '2fdgdfgdfgdf.tk',
    '2prong.com',
    '2sea.org',
    '2sea.xyz',
    '30minutemail.com',
    '30minutenmail.eu',
    '30wave.com',
    '33mail.com',
    '36ru.com',
    '3d-painting.com',
    '3ew.usa.cc',
    '3l6.com',
    '3mail.ga',
    '3trtretgfrfe.tk',
    '4.fackme.gq',
    '418.dk',
    '420blaze.it',
    '42o.org',
    '44556677.igg.biz',
    '466453.usa.cc',
    '487.nut.cc',
    '4gfdsgfdgfd.tk',
    '4mail.cf',
    '4mail.ga',
    '4-n.us',
    '4nmv.ru',
    '4pet.ro',
    '4tb.host',
    '4w.io',
    '4warding.com',
    '4warding.net',
    '4warding.org',
    '510520.org',
    '54np.club',
    '55hosting.net',
    '5dsmartstore.com',
    '5ghgfhfghfgh.tk',
    '5gramos.com',
    '5july.org',
    '5mail.cf',
    '5mail.ga',
    '5music.info',
    '5music.top',
    '5oz.ru',
    '5x25.com',
    '5ymail.com',
    '6.emailfake.ml',
    '60minutemail.com',
    '60-minuten-mail.de',
    '672643.net',
    '675hosting.com',
    '675hosting.net',
    '675hosting.org',
    '69-ew.tk',
    '6hjgjhgkilkj.tk',
    '6ip.us',
    '6mail.cf',
    '6mail.ga',
    '6mail.ml',
    '6paq.com',
    '6url.com',
    '75hosting.com',
    '75hosting.net',
    '75hosting.org',
    '7days-printing.com',
    '7ddf32e.info',
    '7mail.ga',
    '7mail.ml',
    '7mail7.com',
    '7rent.top',
    '7tags.com',
    '7uy35p.tk',
    '806.flu.cc',
    '80665.com',
    '8127ep.com',
    '8191.at',
    '88clean.pro',
    '8chan.co',
    '8mail.cf',
    '8mail.ga',
    '8mail.ml',
    '900k.es',
    '99experts.com',
    '9mail.cf',
    '9me.site',
    '9ox.net',
    '9q.ro',
    'a.a.fbmail.usa.cc',
    'a0.igg.biz',
    'a0f7ukc.com',
    'a1.usa.cc',
    'a2.flu.cc',
    'a41odgz7jh.com',
    'a45.in',
    'a54pd15op.com',
    'aaaw45e.com',
    'abacuswe.us',
    'abakiss.com',
    'a-bc.net',
    'abcmail.email',
    'abcz.info.tm',
    'abilitywe.us',
    'abovewe.us',
    'absolutewe.us',
    'abundantwe.us',
    'abusemail.de',
    'abuser.eu',
    'abyssemail.com',
    'abyssmail.com',
    'ac20mail.in',
    'academiccommunity.com',
    'academywe.us',
    'acceleratewe.us',
    'accentwe.us',
    'acceptwe.us',
    'acclaimwe.us',
    'accordwe.us',
    'accreditedwe.us',
    'acentri.com',
    'acgapp.hk',
    'achievementwe.us',
    'achievewe.us',
    'acornwe.us',
    'activatewe.us',
    'activitywe.us',
    'acuitywe.us',
    'acumenwe.us',
    'adaptivewe.us',
    'adaptwe.us',
    'adbet.co',
    'add3000.pp.ua',
    'adeptwe.us',
    'adesktop.com',
    'adipex7z.com',
    'adiq.eu',
    'admiralwe.us',
    'adobeccepdm.com',
    'adoniswe.us',
    'adpugh.org',
    'adresseemailtemporaire.com',
    'adrianou.gq',
    'adsd.org',
    'advantagewe.us',
    'advantimo.com',
    'adventurewe.us',
    'adventwe.us',
    'advisorwe.us',
    'advocatewe.us',
    'adwaterandstir.com',
    'adx-telecom.com',
    'aegia.net',
    'aegiscorp.net',
    'aegiswe.us',
    'aelo.es',
    'aeonpsi.com',
    'affiliatedwe.us',
    'affinitywe.us',
    'affluentwe.us',
    'affordablewe.us',
    'afmail.com',
    'afrobacon.com',
    'afterhourswe.us',
    'agedmail.com',
    'agendawe.us',
    'agger.ro',
    'agilewe.us',
    'agtx.net',
    'agustusmp3.xyz',
    'aheadwe.us',
    'ahk.jp',
    'airmailhub.com',
    'airsi.de',
    'aistis.xyz',
    'ajaxapp.net',
    'aji.kr',
    'akademiyauspexa.xyz',
    'akapost.com',
    'akash9.gq',
    'akerd.com',
    'akgq701.com',
    'akorde.al',
    'aktiefmail.nl',
    'albionwe.us',
    'alchemywe.us',
    'aldeyaa.ae',
    'alfamailr.org',
    'aliaswe.us',
    'alienware13.com',
    'aligamel.com',
    'alimunjaya.xyz',
    'alisongamel.com',
    'alivance.com',
    'alivewe.us',
    'allaccesswe.us',
    'allamericanwe.us',
    'allaroundwe.us',
    'alldirectbuy.com',
    'allegiancewe.us',
    'allegrowe.us',
    'allen.nom.za',
    'allgoodwe.us',
    'alliancewe.us',
    'allinonewe.us',
    'alloutwe.us',
    'allowed.org',
    'alloywe.us',
    'allprowe.us',
    'allseasonswe.us',
    'allstarwe.us',
    'allthegoodnamesaretaken.org',
    'allurewe.us',
    'almondwe.us',
    'alph.wtf',
    'alphaomegawe.us',
    'alpinewe.us',
    'al-qaeda.us',
    'alsheim.no-ip.org',
    'altairwe.us',
    'altitudewe.us',
    'altuswe.us',
    'alumnimp3.xyz',
    'amadeuswe.us',
    'amail.club',
    'amail.com',
    'amail4.me',
    'ama-trade.de',
    'ama-trans.de',
    'amav.ro',
    'amazon.coms.hk',
    'amazon-aws.org',
    'ambassadorwe.us',
    'amberwe.us',
    'ambiancewe.us',
    'ambitiouswe.us',
    'amelabs.com',
    'americanawe.us',
    'americasbestwe.us',
    'americaswe.us',
    'amicuswe.us',
    'amigowe.us',
    'amilegit.com',
    'amiri.net',
    'amiriindustries.com',
    'amitywe.us',
    'amoksystems.com',
    'amplewe.us',
    'amplifiedwe.us',
    'amplifywe.us',
    'ampsylike.com',
    'an.id.au',
    'analysiswe.us',
    'analyticalwe.us',
    'analyticswe.us',
    'analyticwe.us',
    'anappfor.com',
    'anappthat.com',
    'andreihusanu.ro',
    'andthen.us',
    'animesos.com',
    'anit.ro',
    'ano-mail.net',
    'anonbox.net',
    'anon-mail.de',
    'anonmail.xyz',
    'anonmails.de',
    'anonymail.dk',
    'anonymbox.com',
    'anonymize.com',
    'anonymized.org',
    'anonymous-email.net',
    'anonymousfeedback.net',
    'anonymousmail.org',
    'anonymousness.com',
    'anonymousspeech.com',
    'anotherdomaincyka.tk',
    'anywhere.pw',
    'ansibleemail.com',
    'anthony-junkmail.com',
    'anthropologycommunity.com',
    'antichef.com',
    'antichef.net',
    'antireg.com',
    'antireg.ru',
    'antispam.de',
    'antispam24.de',
    'antispammail.de',
    'ao4ffqty.com',
    'aoeuhtns.com',
    'apfelkorps.de',
    'aphlog.com',
    'apkmd.com',
    'appc.se',
    'appinventor.nl',
    'appixie.com',
    'apps.dj',
    'arduino.hk',
    'ariasexy.tk',
    'ariaz.jetzt',
    'armyspy.com',
    'arno.fi',
    'aron.us',
    'arroisijewellery.com',
    'art-en-ligne.pro',
    'artman-conception.com',
    'arur01.tk',
    'arurgitu.gq',
    'arurimport.ml',
    'arvato-community.de',
    'asana.biz',
    'aschenbrandt.net',
    'asdasd.nl',
    'asdasd.ru',
    'asdfasdfmail.net',
    'asdfghmail.com',
    'asdfmail.net',
    'ashleyandrew.com',
    'asiarap.usa.cc',
    'asooemail.net',
    'asorent.com',
    'ass.pp.ua',
    'asspoo.com',
    'assurancespourmoi.eu',
    'astonut.cf',
    'astonut.ga',
    'astonut.ml',
    'astonut.tk',
    'astroempires.info',
    'asu.mx',
    'asu.su',
    'at.hm',
    'at0mik.org',
    'atvclub.msk.ru',
    'augmentationtechnology.com',
    'auti.st',
    'autorobotica.com',
    'autotwollow.com',
    'aver.com',
    'averdov.com',
    'avia-tonic.fr',
    'avls.pt',
    'awatum.de',
    'awiki.org',
    'aws910.com',
    'axeprim.eu',
    'axiz.org',
    'axon7zte.com',
    'axsup.net',
    'ay33rs.flu.cc',
    'azazazatashkent.tk',
    'azcomputerworks.com',
    'azjuggalos.com',
    'azmeil.tk',
    'b0.nut.cc',
    'b1of96u.com',
    'b2cmail.de',
    'b9x45v1m.com',
    'babau.cf',
    'babau.ga',
    'babau.gq',
    'babau.ml',
    'backalleybowling.info',
    'backflip.cf',
    'badgerland.eu',
    'badhus.org',
    'badoop.com',
    'badpotato.tk',
    'bakar.bid',
    'ballsofsteel.net',
    'bandai.nom.co',
    'banhbeovodich.vn',
    'banit.club',
    'banit.me',
    'bareed.ws',
    'barryogorman.com',
    'barrypov.com',
    'barryspov.com',
    'bartdevos.be',
    'bartoparcadecabinet.com',
    'basscode.org',
    'bauwerke-online.com',
    'baxomale.ht.cx',
    'bazaaboom.com',
    'bbhost.us',
    'bcast.ws',
    'bcb.ro',
    'bccto.me',
    'bdmuzic.pw',
    'bearsarefuzzy.com',
    'beck-it.net',
    'beddly.com',
    'beefmilk.com',
    'beerolympics.se',
    'begoz.com',
    'bei.kr',
    'belamail.org',
    'belastingdienst.pw',
    'belljonestax.com',
    'benipaula.org',
    'beo.kr',
    'bestchoiceusedcar.com',
    'bestoption25.club',
    'bestvpn.top',
    'betr.co',
    'bfo.kr',
    'bgtmail.com',
    'bgx.ro',
    'bho.hu',
    'bho.kr',
    'bidourlnks.com',
    'big1.us',
    'bigprofessor.so',
    'bigstring.com',
    'bigwhoop.co.za',
    'bigwiki.xyz',
    'bij.pl',
    'bin.8191.at',
    'binka.me',
    'binkmail.com',
    'binnary.com',
    'biometicsliquidvitamins.com',
    'bio-muesli.info',
    'bio-muesli.net',
    'bione.co',
    'bitwerke.com',
    'bitymails.us',
    'biz.st',
    'bko.kr',
    'blackmarket.to',
    'bladesmail.net',
    'blip.ch',
    'bloatbox.com',
    'blogmyway.org',
    'blogos.com',
    'blogos.net',
    'blogspam.ro',
    'bloq.ro',
    'bloxter.cu.cc',
    'bluebottle.com',
    'bluedumpling.info',
    'bluewerks.com',
    'blutig.me',
    'boatmail.us',
    'bobmail.info',
    'bobmurchison.com',
    'bodhi.lawlita.com',
    'bofthew.com',
    'bogotadc.info',
    'boimail.com',
    'bongobongo.cf',
    'bongobongo.ga',
    'bongobongo.ml',
    'bongobongo.tk',
    'bonobo.email',
    'bookthemmore.com',
    'bootybay.de',
    'bopunkten.se',
    'borged.com',
    'borged.net',
    'borged.org',
    'bossmail.de',
    'bot.nu',
    'boun.cr',
    'bouncr.com',
    'box.yadavnaresh.com.np',
    'boxformail.in',
    'boximail.com',
    'boxmail.co',
    'boxtemp.com.br',
    'brainonfire.net',
    'brandallday.net',
    'brasx.org',
    'breadtimes.press',
    'breakthru.com',
    'brefmail.com',
    'brennendesreich.de',
    'briggsmarcus.com',
    'britishintelligence.co.uk',
    'broadbandninja.com',
    'browniesgoreng.com',
    'brownieslumer.com',
    'bsnow.net',
    'bspamfree.org',
    'bspooky.com',
    'bst-72.com',
    'btb-notes.com',
    'btc.email',
    'btcmail.pw',
    'btizet.pl',
    'buffemail.com',
    'bugmenever.com',
    'bugmenot.com',
    'bugmenot.ml',
    'bulrushpress.com',
    'bum.net',
    'bumpymail.com',
    'bunchofidiots.com',
    'bund.us',
    'bundes-li.ga',
    'bungabunga.cf',
    'bunsenhoneydew.com',
    'burgercentral.us',
    'burnthespam.info',
    'burstmail.info',
    'businessbackend.com',
    'businesscredit.xyz',
    'businesssuccessislifesuccess.com',
    'buspad.org',
    'buxap.com',
    'buy003.com',
    'buygapfashion.com',
    'buymoreplays.com',
    'buyordie.info',
    'buyusedlibrarybooks.org',
    'bwa33.net',
    'by8006l.com',
    'byebyemail.com',
    'byespm.com',
    'byom.de',
    'c2.hu',
    'c4utar.ml',
    'c51vsgq.com',
    'c7fk799.com',
    'cachedot.net',
    'cafecar.xyz',
    'californiafitnessdeals.com',
    'cam4you.cc',
    'campano.cl',
    'candymail.de',
    'cane.pw',
    'car101.pro',
    'card.zp.ua',
    'carrnelpartners.com',
    'cars2.club',
    'cartelera.org',
    'caseedu.tk',
    'casualdx.com',
    'cavi.mx',
    'cbair.com',
    'cbes.net',
    'cc.liamria',
    'cdnqa.com',
    'cdpa.cc',
    'ce.mintemail.com',
    'ceed.se',
    'cek.pm',
    'cellurl.com',
    'cem.net',
    'centermail.com',
    'centermail.net',
    'centrallosana.ga',
    'central-servers.xyz',
    'cetpass.com',
    'cfo2go.ro',
    'ch.tc',
    'chacuo.net',
    'chammy.info',
    'changingemail.com',
    'cheap3ddigitalcameras.com',
    'cheaphorde.com',
    'cheaphub.net',
    'cheatmail.de',
    'checknew.pw',
    'chef.asana.biz',
    'chewiemail.com',
    'chibakenma.ml',
    'chickenkiller.com',
    'chielo.com',
    'childsavetrust.org',
    'chilelinks.cl',
    'chilkat.com',
    'chinatov.com',
    'chithinh.com',
    'choco.la',
    'chogmail.com',
    'choicemail1.com',
    'chong-mail.com',
    'chong-mail.net',
    'chong-mail.org',
    'chris.burgercentral.us',
    'christopherfretz.com',
    'chumpstakingdumps.com',
    'cid.kr',
    'cigar-auctions.com',
    'civilizationdesign.xyz',
    'civvic.ro',
    'civx.org',
    'ckaazaza.tk',
    'ckiso.com',
    'cko.kr',
    'cl.gl',
    'cl0ne.net',
    'clandest.in',
    'clashatclintonemail.com',
    'clay.xyz',
    'cl-cl.org',
    'clean.pro',
    'clearwatermail.info',
    'clickanerd.net',
    'clinicatbf.com',
    'clintonemailhearing.com',
    'clipmail.eu',
    'clixser.com',
    'cloud99.pro',
    'cloud99.top',
    'clrmail.com',
    'cls-audio.club',
    'clubfier.com',
    'cmail.club',
    'cmail.com',
    'cmail.net',
    'cmail.org',
    'cnamed.com',
    'cnew.ir',
    'cnmsg.net',
    'cnn.coms.hk',
    'cnsds.de',
    'co.cc',
    'cobarekyo1.ml',
    'cock.li',
    'cocodani.cf',
    'cocovpn.com',
    'codeandscotch.com',
    'codivide.com',
    'codupmyspace.com',
    'cognitiveways.xyz',
    'coieo.com',
    'colafanta.cf',
    'coldemail.info',
    'colorweb.cf',
    'com.ar',
    'comilzilla.org',
    'communitybuildingworks.xyz',
    'compareshippingrates.org',
    'completegolfswing.com',
    'coms.hk',
    'comsafe-mail.net',
    'comwest.de',
    'concealed.company',
    'conf.work',
    'confidential.life',
    'config.work',
    'consumerriot.com',
    'contbay.com',
    'contentwanted.com',
    'contractor.net',
    'contrasto.cu.cc',
    'cookiecooker.de',
    'cool.fr.nf',
    'coolandwacky.us',
    'coolimpool.org',
    'correo.blogos.net',
    'cortex.kicks-ass.net',
    'cosmorph.com',
    'courriel.fr.nf',
    'courrieltemporaire.com',
    'coza.ro',
    'cpsystems.ru',
    'cr97mt49.com',
    'crankhole.com',
    'crankmails.com',
    'crapmail.org',
    'crastination.de',
    'crazespaces.pw',
    'crazymailing.com',
    'cream.pink',
    'cross-law.ga',
    'cross-law.gq',
    'crossroadsmail.com',
    'crotslep.ml',
    'crotslep.tk',
    'csh.ro',
    'csoftmail.cn',
    'cszbl.com',
    'ctmailing.us',
    'ctos.ch',
    'cu.cc',
    'cubiclink.com',
    'cultmovie.com',
    'cumallover.me',
    'curlhph.tk',
    'curryworld.de',
    'cust.in',
    'customs2g3.com',
    'cutout.club',
    'cuvox.de',
    'cyber-innovation.club',
    'cyber-phone.eu',
    'cybersex.com',
    'cylab.org',
    'czqjii8.com',
    'd3p.dk',
    'd58pb91.com',
    'd8u.us',
    'dab.ro',
    'dacha-24.ru',
    'dacoolest.com',
    'daemsteam.com',
    'daintly.com',
    'damai.webcam',
    'dammexe.net',
    'damnthespam.com',
    'dancemanual.com',
    'dandikmail.com',
    'darkharvestfilms.com',
    'darknode.org',
    'daryxfox.net',
    'dasdasdascyka.tk',
    'dash-pads.com',
    'dataarca.com',
    'datafilehost',
    'datarca.com',
    'datazo.ca',
    'datum2.com',
    'davidkoh.net',
    'davidlcreative.com',
    'dayrep.com',
    'dbo.kr',
    'dbunker.com',
    'dcemail.com',
    'ddcrew.com',
    'ddnsfree.com',
    'ddosed.us',
    'de.sytes.net',
    'de-a.org',
    'deadaddress.com',
    'deadchildren.org',
    'deadfake.cf',
    'deadfake.ga',
    'deadfake.ml',
    'deadfake.tk',
    'deadspam.com',
    'deagot.com',
    'dealja.com',
    'dealrek.com',
    'decoymail.mx',
    'deekayen.us',
    'de-fake.instafly.cf',
    'defomail.com',
    'degradedfun.net',
    'delayload.com',
    'delayload.net',
    'delikkt.de',
    'demen.ml',
    'derder.net',
    'derkombi.de',
    'der-kombi.de',
    'derluxuswagen.de',
    'despam.it',
    'despammed.com',
    'devnullmail.com',
    'dextm.ro',
    'deyom.com',
    'dfgggg.org',
    'dfgh.net',
    'dfghj.ml',
    'dharmatel.net',
    'dhm.ro',
    'dialogus.com',
    'diapaulpainting.com',
    'dicksinhisan.us',
    'dicksinmyan.us',
    'digdown.xyz',
    'digitalmariachis.com',
    'digitalsanctuary.com',
    'dildosfromspace.com',
    'dingbone.com',
    'dinkmail.com',
    'disaq.com',
    'disario.info',
    'disbox.org',
    'discard.cf',
    'discard.email',
    'discard.ga',
    'discard.gq',
    'discard.ml',
    'discard.tk',
    'discard-email.cf',
    'discardmail.com',
    'discardmail.de',
    'discordmail.com',
    'discoverwatch.com',
    'disign-concept.eu',
    'disign-revelation.com',
    'dispo.in',
    'dispomail.eu',
    'disposable.cf',
    'disposable.ga',
    'disposable.ml',
    'disposableaddress.com',
    'disposable-email.ml',
    'disposableemail.org',
    'disposableemailaddresses.com',
    'disposableinbox.com',
    'disposablemails.com',
    'dispose.it',
    'disposeamail.com',
    'disposemail.com',
    'dispostable.com',
    'divad.ga',
    'divermail.com',
    'divismail.ru',
    'diwaq.com',
    'dko.kr',
    'dlemail.ru',
    'dm.w3internet.co.uk',
    'dm.w3internet.co.ukexample.com',
    'dmail.kyty.net',
    'dmarc.ro',
    'dnsdeer.com',
    'dnses.ro',
    'doanart.com',
    'dob.jp',
    'docmail.com',
    'docs.coms.hk',
    'dodgeit.com',
    'dodgemail.de',
    'dodgit.com',
    'dodgit.org',
    'dodsi.com',
    'doiea.com',
    'dolphinnet.net',
    'domforfb1.tk',
    'domforfb18.tk',
    'domforfb19.tk',
    'domforfb2.tk',
    'domforfb23.tk',
    'domforfb27.tk',
    'domforfb29.tk',
    'domforfb3.tk',
    'domforfb4.tk',
    'domforfb5.tk',
    'domforfb6.tk',
    'domforfb7.tk',
    'domforfb8.tk',
    'domforfb9.tk',
    'domozmail.com',
    'donemail.ru',
    'dontreg.com',
    'dontsendmespam.de',
    'doquier.tk',
    'dotman.de',
    'dot-ml.ml',
    'dot-ml.tk',
    'dotmsg.com',
    'dotslashrage.com',
    'douchelounge.com',
    'doxcity.net',
    'doy.kr',
    'dozvon-spb.ru',
    'dp76.com',
    'dqkerui.com',
    'dr.com',
    'dr69.site',
    'dragons-spirit.org',
    'drama.tw',
    'drdrb.com',
    'drdrb.net',
    'dred.ru',
    'drevo.si',
    'drivetagdev.com',
    'droolingfanboy.de',
    'dropcake.de',
    'droplar.com',
    'droplister.com',
    'dropmail.me',
    'drynic.com',
    'dsiay.com',
    'dspwebservices.com',
    'dt.com',
    'duam.net',
    'duck2.club',
    'dudmail.com',
    'duk33.com',
    'dukedish.com',
    'dumpandjunk.com',
    'dump-email.info',
    'dumpmail.de',
    'dumpyemail.com',
    'durandinterstellar.com',
    'duskmail.com',
    'dwse.edu.pl',
    'dyceroprojects.com',
    'dynu.net',
    'dz17.net',
    'dz-geek.org',
    'e0yk-mail.ml',
    'e3z.de',
    'e4ward.com',
    'e7n06wz.com',
    'eastwan.net',
    'easytrashmail.com',
    'easy-trash-mail.com',
    'eatmea2z.club',
    'eatrnet.com',
    'eb609s25w.com',
    'ebano.campano.cl',
    'ebeschlussbuch.de',
    'ebs.com.ar',
    'ecallheandi.com',
    'echt-mail.de',
    'eco.ilmale.it',
    'ecolo-online.fr',
    'edgex.ru',
    'edinburgh-airporthotels.com',
    'edrishn.xyz',
    'edv.to',
    'ee1.pl',
    'ee2.pl',
    'eelmail.com',
    'efo.kr',
    'efxs.ca',
    'eho.kr',
    'einfach.to',
    'einmalmail.de',
    'einrot.com',
    'einrot.de',
    'eintagsmail.de',
    'elearningjournal.org',
    'electro.mn',
    'elitevipatlantamodels.com',
    'ely.kr',
    'email.cbes.net',
    'e-mail.com',
    'email.net',
    'e-mail.net',
    'e-mail.org',
    'email60.com',
    'emailage.cf',
    'emailage.ga',
    'emailage.gq',
    'emailage.ml',
    'emailage.tk',
    'emaildienst.de',
    'email-fake.cf',
    'emailfake.com',
    'email-fake.com',
    'email-fake.ga',
    'email-fake.gq',
    'emailfake.ml',
    'email-fake.ml',
    'emailfake.nut.cc',
    'email-fake.tk',
    'emailfreedom.ml',
    'emailgo.de',
    'emailhearing.com',
    'emailias.com',
    'emailigo.de',
    'emailinfive.com',
    'emailisvalid.com',
    'email-jetable.fr',
    'emaillime.com',
    'emailll.org',
    'emailmenow.info',
    'emailmiser.com',
    'emailondeck.com',
    'emailproxsy.com',
    'emailresort.com',
    'emails.ga',
    'emailsecurer.com',
    'emailsensei.com',
    'emailsingularity.net',
    'emailspam.cf',
    'emailspam.ga',
    'emailspam.gq',
    'emailspam.ml',
    'emailspam.tk',
    'emailtea.com',
    'emailtech.info',
    'emailtemporanea.com',
    'emailtemporanea.net',
    'emailtemporar.ro',
    'emailtemporario.com.br',
    'emailthe.net',
    'emailtmp.com',
    'emailto.de',
    'emailwarden.com',
    'emailx.at.hm',
    'emailxfer.com',
    'emailz.cf',
    'emailz.ga',
    'emailz.gq',
    'emailz.ml',
    'emeil.in',
    'emeil.ir',
    'emeraldwebmail.com',
    'emeyle.com',
    'emil.com',
    'emkei.cf',
    'emkei.ga',
    'emkei.gq',
    'emkei.ml',
    'emkei.tk',
    'eml.pp.ua',
    'emlhub.com',
    'emlpro.com',
    'emltmp.com',
    'empireanime.ga',
    'empiremail.de',
    'emy.kr',
    'emz.net',
    'enterto.com',
    'enu.kr',
    'envy17.com',
    'eny.kr',
    'eonmech.com',
    'epb.ro',
    'ephemail.net',
    'ephemeral.email',
    'e-postkasten.com',
    'e-postkasten.de',
    'e-postkasten.eu',
    'e-postkasten.info',
    'eqeqeqeqe.tk',
    'eqiluxspam.ga',
    'erasf.com',
    'ericjohnson.ml',
    'ero-tube.org',
    'esc.la',
    'escapehatchapp.com',
    'ese.kr',
    'esemay.com',
    'esgeneri.com',
    'esprity.com',
    'esseriod.com',
    'estate-invest.fr',
    'etgdev.de',
    'ether123.net',
    'ethereum1.top',
    'ethersports.org',
    'etlgr.com',
    'etranquil.com',
    'etranquil.net',
    'etranquil.org',
    'euaqa.com',
    'evanfox.info',
    'everytg.ml',
    'evopo.com',
    'evyush.com',
    'ewa.kr',
    'example.com',
    'exi.kr',
    'exitstageleft.net',
    'explodemail.com',
    'express.net.ua',
    'extremail.ru',
    'eyepaste.com',
    'ez.lv',
    'ezfill.club',
    'ezfill.com',
    'ezlo.co',
    'ezstest.com',
    'f.moza.pl',
    'f4k.es',
    'f5.si',
    'facebook-email.cf',
    'facebook-email.ga',
    'facebook-email.ml',
    'facebookmail.gq',
    'facebookmail.ml',
    'fackme.gq',
    'fadingemail.com',
    'fag.wf',
    'failbone.com',
    'faithkills.com',
    'faithkills.org',
    'fake-box.com',
    'fakedemail.com',
    'fake-email.pp.ua',
    'fakeinbox.cf',
    'fakeinbox.com',
    'fakeinbox.ga',
    'fakeinbox.ml',
    'fakeinbox.tk',
    'fakeinformation.com',
    'fake-mail.cf',
    'fakemail.fr',
    'fake-mail.ga',
    'fake-mail.ml',
    'fakemailgenerator.com',
    'fakemailz.com',
    'fammix.com',
    'fangoh.com',
    'fansworldwide.de',
    'fantasymail.de',
    'farrse.co.uk',
    'fartwallet.com',
    'fastacura.com',
    'fastchevy.com',
    'fastchrysler.com',
    'fastemails.us',
    'fastermail.com',
    'fasternet.biz',
    'fastkawasaki.com',
    'fast-mail.fr',
    'fastmailforyou.net',
    'fastmazda.com',
    'fastmitsubishi.com',
    'fastnissan.com',
    'fastsubaru.com',
    'fastsuzuki.com',
    'fasttoyota.com',
    'fastyamaha.com',
    'fatflap.com',
    'faze.biz',
    'fbi.coms.hk',
    'fbma.tk',
    'fbmail1.ml',
    'fc66998.com',
    'fddns.ml',
    'fdfdsfds.com',
    'feamail.com',
    'fer-gabon.org',
    'fetchnet.co.uk',
    'fettometern.com',
    'fghmail.net',
    'ficken.de',
    'fictionsite.com',
    'fido.be',
    'fightallspam.com',
    'figjs.com',
    'figshot.com',
    'fiifke.de',
    'filbert4u.com',
    'filberts4u.com',
    'film-blog.biz',
    'filzmail.com',
    'findu.pl',
    'fingermouse.org',
    'fir.hk',
    'fishfortomorrow.xyz',
    'fivemail.de',
    'fixmail.tk',
    'fizmail.com',
    'flashbox.5july.org',
    'fleckens.hu',
    'flemail.com',
    'flemail.ru',
    'flitafir.de',
    'flowu.com',
    'flu.cc',
    'flurre.com',
    'flurred.com',
    'flyinggeek.net',
    'flyspam.com',
    'fly-ts.de',
    'fmail.pw',
    'fnzm.net',
    'foobarbot.net',
    'foodbooto.com',
    'footard.com',
    'foquita.com',
    'forecastertests.com',
    'foreskin.cf',
    'foreskin.ga',
    'foreskin.gq',
    'foreskin.tk',
    'forgetmail.com',
    'fornow.eu',
    'forspam.net',
    'forward.cat',
    'four.fackme.gq',
    'foxja.com',
    'foxtrotter.info',
    'foy.kr',
    'fr.nf',
    'fr33mail.info',
    'fragolina2.tk',
    'francanet.com.br',
    'frapmail.com',
    'frappina.tk',
    'frappina99.tk',
    'freebabysittercam.com',
    'freeblackbootytube.com',
    'freebullets.net',
    'freecat.net',
    'freechristianbookstore.com',
    'freedompop.us',
    'free-email.cf',
    'free-email.ga',
    'freefattymovies.com',
    'freelance-france.eu',
    'freeletter.me',
    'freemail.hu',
    'freemail.ms',
    'freemails.cf',
    'freemails.ga',
    'freemails.ml',
    'freemeil.ga',
    'freemeil.gq',
    'freemeil.ml',
    'freemeil.tk',
    'freemommyvids.com',
    'freeplumpervideos.com',
    'freeschoolgirlvids.com',
    'freeshemaledvds.com',
    'freesistercam.com',
    'freesistervids.com',
    'freeteenbums.com',
    'freetubearchive.com',
    'freunde.ru',
    'freundin.ru',
    'friendlymail.co.uk',
    'front14.org',
    'fsagc.xyz',
    'fsfsdf.org',
    'ftp.sh',
    'ftpinc.ca',
    'fuckedupload.com',
    'fuckingduh.com',
    'fuckme69.club',
    'fudgerub.com',
    'fuirio.com',
    'fulvie.com',
    'fun2.biz',
    'fun64.com',
    'fun64.net',
    'funnycodesnippets.com',
    'funnymail.de',
    'furusato.tokyo',
    'furzauflunge.de',
    'fuwamofu.com',
    'fux0ringduh.com',
    'fw2.me',
    'fw6m0bd.com',
    'fxnxs.com',
    'fyii.de',
    'g.ycn.ro',
    'g4hdrop.us',
    'gaf.oseanografi.id',
    'gafy.net',
    'gaggle.net',
    'galaxy.tv',
    'gally.jp',
    'game.com',
    'gamegregious.com',
    'games4free.flu.cc',
    'gamgling.com',
    'garage46.com',
    'garasikita.pw',
    'garbagecollector.org',
    'garbagemail.org',
    'gardenscape.ca',
    'garizo.com',
    'garliclife.com',
    'garrymccooey.com',
    'gav0.com',
    'gawab.com',
    'geew.ru',
    'gehensiemirnichtaufdensack.de',
    'geldwaschmaschine.de',
    'gelitik.in',
    'genderfuck.net',
    'geronra.com',
    'germanmails.biz',
    'gero.us',
    'geschent.biz',
    'get.pp.ua',
    'get1mail.com',
    'get2mail.fr',
    'getairmail.cf',
    'getairmail.com',
    'getairmail.ga',
    'getairmail.gq',
    'getairmail.ml',
    'getairmail.tk',
    'getapet.net',
    'geteit.com',
    'get-mail.cf',
    'get-mail.ga',
    'get-mail.ml',
    'get-mail.tk',
    'getmails.eu',
    'getnada.com',
    'getnowtoday.cf',
    'getonemail.com',
    'getonemail.net',
    'gfcom.com',
    'ghosttexter.de',
    'giaiphapmuasam.com',
    'giantmail.de',
    'gibit.us',
    'ginzi.be',
    'ginzi.co.uk',
    'ginzi.es',
    'ginzi.net',
    'ginzy.co.uk',
    'ginzy.eu',
    'girlsindetention.com',
    'girlsundertheinfluence.com',
    'gishpuppy.com',
    'giuras.club',
    'giuypaiw8.com',
    'givmail.com',
    'gleeze.com',
    'glitch.sx',
    'globaltouron.com',
    'glubex.com',
    'glucosegrin.com',
    'gmaildottrick.com',
    'gmailssdf.com',
    'gmal.com',
    'gmial.com',
    'gnctr-calgary.com',
    'go2usa.info',
    'go2vpn.net',
    'goat.si',
    'godataflow.xyz',
    'godut.com',
    'goemailgo.com',
    'gok.kr',
    'golemico.com',
    'golfilla.info',
    'golidi.net',
    'gomail.in',
    'goodjab.club',
    'googdad.tk',
    'goranko.ga',
    'gorillaswithdirtyarmpits.com',
    'gothere.biz',
    'gotmail.com',
    'gotmail.net',
    'gotmail.org',
    'gotti.otherinbox.com',
    'gowikibooks.com',
    'gowikicampus.com',
    'gowikicars.com',
    'gowikifilms.com',
    'gowikigames.com',
    'gowikimusic.com',
    'gowikimusic.great-host.in',
    'gowikinetwork.com',
    'gowikitravel.com',
    'gowikitv.com',
    'gqlsryi.xyz',
    'grandmamail.com',
    'grandmasmail.com',
    'gratislose.de',
    'great-host.in',
    'greenhousemail.com',
    'greensloth.com',
    'greenst.info',
    'greggamel.com',
    'greggamel.net',
    'gregorsky.zone',
    'gregorygamel.com',
    'gregorygamel.net',
    'greyjack.com',
    'grish.de',
    'griuc.schule',
    'grn.cc',
    'grr.la',
    'gs-arc.org',
    'gsredcross.org',
    'gsrv.co.uk',
    'gsxstring.ga',
    'gudanglowongan.com',
    'guerillamail.biz',
    'guerillamail.com',
    'guerillamail.de',
    'guerillamail.info',
    'guerillamail.net',
    'guerillamail.org',
    'guerillamailblock.com',
    'guerrillamail.biz',
    'guerrillamail.com',
    'guerrillamail.de',
    'guerrillamail.info',
    'guerrillamail.net',
    'guerrillamail.org',
    'guerrillamailblock.com',
    'gustr.com',
    'gwspt71.com',
    'gynzi.co.uk',
    'gynzi.es',
    'gynzy.at',
    'gynzy.es',
    'gynzy.eu',
    'gynzy.gr',
    'gynzy.info',
    'gynzy.lt',
    'gynzy.mobi',
    'gynzy.pl',
    'gynzy.ro',
    'gynzy.sk',
    'gzb.ro',
    'h.mintemail.com',
    'h1z8ckvz.com',
    'h2-yy.nut.cc',
    'h8s.org',
    'h9js8y6.com',
    'habitue.net',
    'hacccc.com',
    'hacked.jp',
    'hackersquad.tk',
    'hackrz.xyz',
    'hackthatbit.ch',
    'hahawrong.com',
    'haltospam.com',
    'happygoluckyclub.com',
    'happykorea.club',
    'happykoreas.xyz',
    'haqed.com',
    'harakirimail.com',
    'haribu.com',
    'haribu.net',
    'harmonyst.xyz',
    'hartbot.de',
    'hasanmail.ml',
    'hash.pp.ua',
    'hatespam.org',
    'hat-geld.de',
    'hawrong.com',
    'haydoo.com',
    'hazelnut4u.com',
    'hazelnuts4u.com',
    'hazmatshipping.org',
    'hcac.net',
    'hdmoviestore.us',
    'headstrong.de',
    'healyourself.xyz',
    'heathenhammer.com',
    'heathenhero.com',
    'hecat.es',
    'hellodream.mobi',
    'helloricky.com',
    'helpinghandtaxcenter.org',
    'herp.in',
    'herpderp.nl',
    'hezll.com',
    'hi2.in',
    'hi5.si',
    'hiddencorner.xyz',
    'hiddentragedy.com',
    'hidebox.org',
    'hidemail.de',
    'hidemail.pro',
    'hidemail.us',
    'hideme.be',
    'hidemyass.com',
    'hidzz.com',
    'highbros.org',
    'hiru-dea.com',
    'hix.kr',
    'hmail.us',
    'hmamail.com',
    'hmh.ro',
    'hoanggiaanh.com',
    'hoanglong.tech',
    'hochsitze.com',
    'hoer.pw',
    'holl.ga',
    'honor-8.com',
    'hopemail.biz',
    'hornyalwary.top',
    'horsefucker.org',
    'horvathurtablahoz.ml',
    'hostcalls.com',
    'hostmonitor.net',
    'hotakama.tk',
    'hotmai.com',
    'hot-mail.cf',
    'hot-mail.ga',
    'hot-mail.gq',
    'hot-mail.ml',
    'hot-mail.tk',
    'hotmails.com',
    'hotmial.com',
    'hotpop.com',
    'housat.com',
    'hpc.tw',
    'hs.vc',
    'hsbc.coms.hk',
    'hstermail.com',
    'ht.cx',
    'huajiachem.cn',
    'hukkmu.tk',
    'hulapla.de',
    'humaility.com',
    'humn.ws.gy',
    'hungpackage.com',
    'hunrap.usa.cc',
    'hush.ai',
    'hush.com',
    'hushmail.cf',
    'huskion.net',
    'hvastudiesucces.nl',
    'hvtechnical.com',
    'hwsye.net',
    'i201zzf8x.com',
    'i2pmail.org',
    'i-3gk.cf',
    'i-3gk.ga',
    'i-3gk.gq',
    'i-3gk.ml',
    'i4j0j3iz0.com',
    'iaoss.com',
    'ibm.coms.hk',
    'ibnuh.bz',
    'ibsats.com',
    'icantbelieveineedtoexplainthisshit.com',
    'icemovie.link',
    'ichigo.me',
    'icx.in',
    'icx.ro',
    'id.au',
    'idigo.org',
    'ieatspam.eu',
    'ieatspam.info',
    'ieh-mail.de',
    'ige.es',
    'igg.biz',
    'ignoremail.com',
    'ihateyoualot.info',
    'ihaxyour.info',
    'ihazspam.ca',
    'iheartspam.org',
    'ikbenspamvrij.nl',
    'iki.kr',
    'iku.us',
    'illistnoise.com',
    'ilmale.it',
    'ilnostrogrossograssomatrimoniomolisano.com',
    'ilovespam.com',
    'imails.info',
    'imankul.com',
    'imgof.com',
    'imgv.de',
    'immo-gerance.info',
    'imovie.link',
    'imstations.com',
    'inaby.com',
    'inapplicable.org',
    'inbax.tk',
    'inbound.plus',
    'inbox.si',
    'inbox2.info',
    'inboxalias.com',
    'inboxbear.com',
    'inboxclean.com',
    'inboxclean.org',
    'inboxdesign.me',
    'inboxed.im',
    'inboxed.pw',
    'inboxhub.net',
    'inboxproxy.com',
    'inboxstore.me',
    'inclusiveprogress.com',
    'incognitomail.com',
    'incognitomail.net',
    'incognitomail.org',
    'incq.com',
    'ind.st',
    'indieclad.com',
    'indirect.ws',
    'indomaed.pw',
    'indomina.cf',
    'indonesianherbalmedicine.com',
    'indoserver.stream',
    'indosukses.press',
    'ineec.net',
    'infest.org',
    'infocom.zp.ua',
    'info-radio.ml',
    'inggo.org',
    'inmynetwork.cf',
    'inmynetwork.ga',
    'inmynetwork.gq',
    'inmynetwork.ml',
    'inmynetwork.tk',
    'inoutmail.de',
    'inoutmail.eu',
    'inoutmail.info',
    'inoutmail.net',
    'inpowiki.xyz',
    'insanumingeniumhomebrew.com',
    'insorg-mail.info',
    'instantblingmail.info',
    'instantemailaddress.com',
    'instantlyemail.com',
    'instant-mail.de',
    'instantmail.fr',
    'instantmailaddress.com',
    'intel.coms.hk',
    'internetoftags.com',
    'interserver.ga',
    'interstats.org',
    'intersteller.com',
    'iozak.com',
    'ip4.pp.ua',
    'ip6.li',
    'ip6.pp.ua',
    'ipdeer.com',
    'ipoo.org',
    'ippandansei.tk',
    'ipsur.org',
    'ipswell.com',
    'irabops.com',
    'irc.so',
    'ircbox.xyz',
    'irish2me.com',
    'irishspringrealty.com',
    'iroid.com',
    'ironiebehindert.de',
    'irr.kr',
    'irssi.tv',
    'is.af',
    'isdaq.com',
    'islam.igg.biz',
    'ispuntheweb.com',
    'ispyco.ru',
    'istakalisa.club',
    'istii.ro',
    'isukrainestillacountry.com',
    'it7.ovh',
    'i-taiwan.tv',
    'itmtx.com',
    'itsme.edu.pl',
    'itunesgiftcodegenerator.com',
    'iwi.net',
    'ixx.io',
    'j3rqt89ez.com',
    'jafps.com',
    'jamit.com.au',
    'janproz.com',
    'jcpclothing.ga',
    'jdmadventures.com',
    'jdz.ro',
    'jellow.ml',
    'jellyrolls.com',
    'jeramywebb.com',
    'je-recycle.info',
    'jetable.com',
    'jetable.fr.nf',
    'jetable.net',
    'jetable.org',
    'jetable.pp.ua',
    'jetableemail.com',
    'jet-renovation.fr',
    'jil.kr',
    'jmail.ro',
    'jnxjn.com',
    'jobbikszimpatizans.hu',
    'jobposts.net',
    'jobs-to-be-done.net',
    'joelpet.com',
    'joetestalot.com',
    'jopho.com',
    'josefadventures.org',
    'josse.ltd',
    'jourrapide.com',
    'j-p.us',
    'jpco.org',
    'jredm.com',
    'jsonp.ro',
    'jsrsolutions.com',
    'jswfdb48z.com',
    'jto.kr',
    'jungkamushukum.com',
    'junk.to',
    'junk1e.com',
    'junkmail.com',
    'junkmail.ga',
    'junkmail.gq',
    'jupimail.com',
    'just4spam.com',
    'justemail.ml',
    'justonemail.net',
    'jv6hgh1.com',
    'jwk4227ufn.com',
    'jwork.ru',
    'jyliananderik.com',
    'k3663a40w.com',
    'k4ds.org',
    'kadag.ir',
    'kademen.com',
    'kah.pw',
    'kaijenwan.com',
    'kakadua.net',
    'kalapi.org',
    'kampoeng3d.club',
    'kamsg.com',
    'kanker.website',
    'kaovo.com',
    'karatraman.ml',
    'kariplan.com',
    'kartvelo.com',
    'kasmail.com',
    'kaspop.com',
    'katztube.com',
    'kazelink.ml',
    'kcrw.de',
    'keepmymail.com',
    'kein.hk',
    'keinhirn.de',
    'keinpardon.de',
    'keipino.de',
    'kekecog.com',
    'kemptvillebaseball.com',
    'kemska.pw',
    'kennedy808.com',
    'ketiksms.club',
    'kiani.com',
    'kickmark.com',
    'kiham.club',
    'killmail.com',
    'killmail.net',
    'kimsdisk.com',
    'kingsq.ga',
    'kiois.com',
    'kir.ch.tc',
    'kismail.ru',
    'kisstwink.com',
    'kitnastar.com',
    'kitten-mittons.com',
    'klammlose.org',
    'klassmaster.com',
    'klassmaster.net',
    'klipp.su',
    'klipschx12.com',
    'kloap.com',
    'kludgemush.com',
    'klzlk.com',
    'kmhow.com',
    'knol-power.nl',
    'koiqe.com',
    'kommunity.biz',
    'kon42.com',
    'kook.ml',
    'kopagas.com',
    'kopaka.net',
    'kormail.xyz',
    'kosmetik-obatkuat.com',
    'kostenlosemailadresse.de',
    'koszmail.pl',
    'kozow.com',
    'krsw.tk',
    'krypton.tk',
    'kuai909.com',
    'kuaijenwan.com',
    'kuatcak.cf',
    'kuatcak.tk',
    'kuatmail.gq',
    'kuatmail.tk',
    'kuhrap.com',
    'kulturbetrieb.info',
    'kurzepost.de',
    'kusrc.com',
    'kwift.net',
    'kwilco.net',
    'kyal.pl',
    'l33r.eu',
    'l5.ca',
    'l8oaypr.com',
    'labetteraverouge.at',
    'labo.ch',
    'lacedmail.com',
    'lackmail.net',
    'lackmail.ru',
    'ladymacbeth.tk',
    'lags.us',
    'lain.ch',
    'lajoska.pe.hu',
    'lakelivingstonrealestate.com',
    'lal.kr',
    'landmail.co',
    'laoeq.com',
    'laoho.com',
    'last-chance.pro',
    'lastmail.co',
    'lastmail.com',
    'lavabit.com',
    'lawlita.com',
    'lazyinbox.com',
    'lazyinbox.us',
    'lbe.kr',
    'l-c-a.us',
    'ldop.com',
    'ldtp.com',
    'ledoktre.com',
    'lee.mx',
    'leeching.net',
    'leemail.me',
    'legalrc.loan',
    'lellno.gq',
    'lenovog4.com',
    'lesbugs.com',
    'letmeinonthis.com',
    'letmymail.com',
    'letthemeatspam.com',
    'lexisense.com',
    'lez.se',
    'lgxscreen.com',
    'lhsdv.com',
    'liamcyrus.com',
    'libox.fr',
    'lifebyfood.com',
    'lifetotech.com',
    'ligsb.com',
    'likesyouback.com',
    'lillemap.net',
    'lilo.me',
    'lilylee.com',
    'lindenbaumjapan.com',
    'link2mail.net',
    'linkedintuts2016.pw',
    'linuxmail.so',
    'linuxpl.eu',
    'liquidmail.de',
    'litedrop.com',
    'liveradio.tk',
    'lkgn.se',
    'lko.kr',
    'llogin.ru',
    'lmcudh4h.com',
    'loadby.us',
    'loan101.pro',
    'localserv.no-ip.org',
    'locanto1.club',
    'locantofuck.top',
    'locantospot.top',
    'locantowsite.club',
    'locateme10.com',
    'locomodev.net',
    'login-email.cf',
    'login-email.ga',
    'login-email.ml',
    'login-email.tk',
    'logular.com',
    'loh.pp.ua',
    'loin.in',
    'lol.ovpn.to',
    'lolfreak.net',
    'lolitka.cf',
    'lolitka.ga',
    'lolitka.gq',
    'lolito.tk',
    'lolmail.biz',
    'lom.kr',
    'london2.space',
    'lookugly.com',
    'lopl.co.cc',
    'lordsofts.com',
    'lortemail.dk',
    'losemymail.com',
    'lostpositive.xyz',
    'lovebitco.in',
    'lovefall.ml',
    'lovemeleaveme.com',
    'lovesea.gq',
    'loy.kr',
    'lpfmgmtltd.com',
    'lr7.us',
    'lr78.com',
    'lroid.com',
    'lru.me',
    'luckymail.org',
    'lucyu.com',
    'lukecarriere.com',
    'lukemail.info',
    'lukop.dk',
    'luo.kr',
    'luv2.us',
    'lyfestylecreditsolutions.com',
    'm21.cc',
    'm2r60ff.com',
    'm4ilweb.info',
    'maaill.com',
    'maboard.com',
    'macr2.com',
    'macromaid.com',
    'magamail.com',
    'maggotymeat.ga',
    'magicbox.ro',
    'maidlow.info',
    'mail.aws910.com',
    'mail.by',
    'mail.illistnoise.com',
    'mail.mailinator.com',
    'mail.me',
    'mail.mezimages.net',
    'mail.partskyline.com',
    'mail.wtf',
    'mail.zp.ua',
    'mail1.drama.tw',
    'mail1.hacked.jp',
    'mail1.ismoke.hk',
    'mail1.i-taiwan.tv',
    'mail1.kaohsiung.tv',
    'mail1.kein.hk',
    'mail114.net',
    'mail1a.de',
    'mail2.drama.tw',
    'mail2.info.tm',
    'mail2.ntuz.me',
    'mail2.space',
    'mail2.worksmobile.ml',
    'mail21.cc',
    'mail22.club',
    'mail22.space',
    'mail2rss.org',
    'mail2tor.com',
    'mail2world.com',
    'mail3.drama.tw',
    'mail333.com',
    'mail4.drama.tw',
    'mail4trash.com',
    'mail4you.usa.cc',
    'mail5.drama.tw',
    'mail666.ru',
    'mail707.com',
    'mail72.com',
    'mailabconline.com',
    'mailadadad.org',
    'mailapi.ru',
    'mailback.com',
    'mailbidon.com',
    'mailbiz.biz',
    'mailblocks.com',
    'mailblog.biz',
    'mailbox72.biz',
    'mailbox80.biz',
    'mailbox92.biz',
    'mailbucket.org',
    'mailcat.biz',
    'mailcatch.com',
    'mailchop.com',
    'mailcker.com',
    'mailde.de',
    'mailde.info',
    'maildrop.cc',
    'maildrop.cf',
    'maildrop.ga',
    'maildrop.gq',
    'maildrop.ml',
    'maildu.de',
    'maildump.tk',
    'maildx.com',
    'mail-easy.fr',
    'maileater.com',
    'mailed.in',
    'mailed.ro',
    'maileimer.de',
    'maileme101.com',
    'mailexpire.com',
    'mailf5.com',
    'mailfa.tk',
    'mail-fake.com',
    'mailfall.com',
    'mail-filter.com',
    'mailfish.de',
    'mailforspam.com',
    'mailfree.ga',
    'mailfree.gq',
    'mailfree.ml',
    'mailfreeonline.com',
    'mailfs.com',
    'mailgov.info',
    'mailguard.me',
    'mailgutter.com',
    'mailhazard.com',
    'mailhazard.us',
    'mailhero.io',
    'mailhz.me',
    'mailimate.com',
    'mailin8r.com',
    'mailinatar.com',
    'mailinater.com',
    'mailinator.co.uk',
    'mailinator.com',
    'mailinator.gq',
    'mailinator.info',
    'mailinator.net',
    'mailinator.org',
    'mailinator.pl',
    'mailinator.us',
    'mailinator2.com',
    'mailincubator.com',
    'mailismagic.com',
    'mailita.tk',
    'mailjunk.cf',
    'mailjunk.ga',
    'mailjunk.gq',
    'mailjunk.ml',
    'mailjunk.tk',
    'mailkor.xyz',
    'mailmate.com',
    'mailme.gq',
    'mailme.ir',
    'mailme.lv',
    'mailme24.com',
    'mailmetrash.com',
    'mailmetrash.comilzilla.org',
    'mailmoat.com',
    'mailmoth.com',
    'mailms.com',
    'mailna.biz',
    'mailna.in',
    'mailna.me',
    'mailnator.com',
    'mailnesia.com',
    'mailnull.com',
    'mailonaut.com',
    'mailorc.com',
    'mailorg.org',
    'mail-owl.com',
    'mailpick.biz',
    'mailpooch.com',
    'mailproxsy.com',
    'mailquack.com',
    'mailrc.biz',
    'mailrock.biz',
    'mailsac.com',
    'mailscheap.us',
    'mailscrap.com',
    'mailseal.de',
    'mailshell.com',
    'mailsiphon.com',
    'mailslapping.com',
    'mailslite.com',
    'mailspam.xyz',
    'mailspeed.ru',
    'mail-temp.com',
    'mailtemp.info',
    'mailtemp.net',
    'mailtemporaire.com',
    'mail-temporaire.com',
    'mailtemporaire.fr',
    'mail-temporaire.fr',
    'mail-tester.com',
    'mailthunder.ml',
    'mailtome.de',
    'mailtothis.com',
    'mailtrash.net',
    'mailtv.net',
    'mailtv.tv',
    'mailwithyou.com',
    'mailzi.ru',
    'mailzilla.com',
    'mailzilla.org',
    'majorleaguemail.com',
    'makemenaughty.club',
    'makemetheking.com',
    'malahov.de',
    'malayalamdtp.com',
    'malibucoding.com',
    'mallinator.com',
    'mandraghen.cf',
    'manifestgenerator.com',
    'mansiondev.com',
    'manybrain.com',
    'mao.igg.biz',
    'markmurfin.com',
    'martyvole.ml',
    'mastahype.net',
    'maswae.world',
    'matchpol.net',
    'materiali.ml',
    'mattmason.xyz',
    'max88.club',
    'mbe.kr',
    'mbx.cc',
    'mcache.net',
    'mciek.com',
    'mechanicalresumes.com',
    'medsheet.com',
    'meepsheep.eu',
    'mega.zik.dj',
    'meinspamschutz.de',
    'mejjang.xyz',
    'meltedbrownies.com',
    'meltmail.com',
    'merry.pink',
    'messagebeamer.de',
    'messageden.net',
    'messagesafe.co',
    'messwiththebestdielikethe.rest',
    'metroset.net',
    'metuwar.tk',
    'mezimages.net',
    'mfsa.ru',
    'mhwolf.net',
    'miaferrari.com',
    'micsocks.net',
    'midcoastcustoms.com',
    'midcoastcustoms.net',
    'midcoastsolutions.com',
    'midcoastsolutions.net',
    'midlertidig.com',
    'midlertidig.net',
    'midlertidig.org',
    'mierdamail.com',
    'migmail.net',
    'migmail.pl',
    'migserver2.ml',
    'migumail.com',
    'mihanmail.ir',
    'mihep.com',
    'mijnhva.nl',
    'milavitsaromania.ro',
    'mildin.org.ua',
    'mindless.com',
    'minecraftrabbithole.com',
    'ministry-of-silly-walks.de',
    'minsmail.com',
    'mintemail.com',
    'miodonski.ch',
    'miraigames.net',
    'misterpinball.de',
    'mji.ro',
    'mjukglass.nu',
    'mko.kr',
    'mkpfilm.com',
    'ml8.ca',
    'mm.my',
    'mm5.se',
    'mmail.igg.biz',
    'mmailinater.com',
    'mmmmail.com',
    'moakt.co',
    'moakt.com',
    'moakt.ws',
    'mobi.web.id',
    'mobileninja.co.uk',
    'mobilevpn.top',
    'moburl.com',
    'mockmyid.co',
    'mockmyid.com',
    'moeri.org',
    'mohmal.com',
    'mohmal.im',
    'mohmal.in',
    'mohmal.tech',
    'momentics.ru',
    'moncourrier.fr.nf',
    'monemail.fr.nf',
    'moneypipe.net',
    'monmail.fr.nf',
    'monumentmail.com',
    'moonwake.com',
    'moot.es',
    'morahdsl.cf',
    'moreawesomethanyou.com',
    'moreorcs.com',
    'morriesworld.ml',
    'motique.de',
    'mountainregionallibrary.net',
    'mox.pp.ua',
    'moza.pl',
    'mr24.co',
    'mrblacklist.gq',
    'mrresourcepacks.tk',
    'msa.minsmail.com',
    'msgden.com',
    'msgos.com',
    'msgsafe.ninja',
    'msk.ru',
    'mspeciosa.com',
    'msrc.ml',
    'mswork.ru',
    'msxd.com',
    'mt2009.com',
    'mt2014.com',
    'mt2015.com',
    'mt2016.com',
    'mt2017.com',
    'mtmdev.com',
    'muathegame.com',
    'muchomail.com',
    'mucincanon.com',
    'muehlacker.tk',
    'muell.email',
    'mufux.com',
    'mugglenet.org',
    'munoubengoshi.gq',
    'mustbedestroyed.org',
    'mutant.me',
    'muttwalker.net',
    'mvrht.com',
    'mvrht.net',
    'mwarner.org',
    'mx0.wwwnew.eu',
    'mxfuel.com',
    'my.vondata.com.ar',
    'my10minutemail.com',
    'myalias.pw',
    'mybitti.de',
    'mycard.net.ua',
    'mycleaninbox.net',
    'mycorneroftheinter.net',
    'myde.ml',
    'mydemo.equipment',
    'myecho.es',
    'myemailboxy.com',
    'myindohome.services',
    'myinterserver.ml',
    'mykickassideas.com',
    'mymail-in.net',
    'mymailjos.cf',
    'mymailjos.ga',
    'mymailjos.tk',
    'mymailoasis.com',
    'mymailto.cf',
    'mymailto.ga',
    'myn4s.ddns.net',
    'myneocards.cz',
    'mynetstore.de',
    'mynetwork.cf',
    'myopang.com',
    'mypacks.net',
    'mypartyclip.de',
    'myphantomemail.com',
    'mysamp.de',
    'myspaceinc.com',
    'myspaceinc.net',
    'myspaceinc.org',
    'myspacepimpedup.com',
    'myspamless.com',
    'mystvpn.com',
    'mytemp.email',
    'mytempemail.com',
    'mytempmail.com',
    'mythnick.club',
    'mytrashmail.com',
    'mywarnernet.net',
    'myzx.com',
    'n1nja.org',
    'nabuma.com',
    'nacho.pw',
    'nada.email',
    'nada.ltd',
    'nakedtruth.biz',
    'nanonym.ch',
    'napalm51.cf',
    'napalm51.ga',
    'napalm51.gq',
    'napalm51.ml',
    'napalm51.tk',
    'nationalgardeningclub.com',
    'naturalious.com',
    'naver.com',
    'nctuiem.xyz',
    'negated.com',
    'neibu306.com',
    'neibu963.com',
    'neko2.net',
    'neomailbox.com',
    'nepwk.com',
    'nervmich.net',
    'nervtmich.net',
    'net.ua',
    'netmails.com',
    'netmails.net',
    'netricity.nl',
    'netris.net',
    'netviewer-france.com',
    'netzidiot.de',
    'neverbox.com',
    'nevermail.de',
    'newbpotato.tk',
    'newdawnnm.xyz',
    'new-purse.com',
    'nextstopvalhalla.com',
    'nezdiro.org',
    'nezzart.com',
    'nfast.net',
    'nguyenusedcars.com',
    'nh3.ro',
    'nice-4u.com',
    'nicewoodenbaskets.com',
    'nicknassar.com',
    'niepodam.pl',
    'nie-podam.pl',
    'nigge.rs',
    'nike.coms.hk',
    'nincsmail.com',
    'nincsmail.hu',
    'niwl.net',
    'nko.kr',
    'nl.szucsati.net',
    'nm7.cc',
    'nmail.cf',
    'nnh.com',
    'nnot.net',
    'noblepioneer.com',
    'nobugmail.com',
    'nobulk.com',
    'nobuma.com',
    'noclickemail.com',
    'nodezine.com',
    'nodie.cc',
    'nodnor.club',
    'nogmailspam.info',
    'noicd.com',
    'nokiamail.com',
    'nom.za',
    'nomail.cf',
    'nomail.ch',
    'nomail.ga',
    'nomail.pw',
    'nomail.xl.cx',
    'nomail2me.com',
    'nomailthankyou.com',
    'nomorespamemails.com',
    'nonspam.eu',
    'nonspammer.de',
    'nonze.ro',
    'noref.in',
    'norih.com',
    'norseforce.com',
    'northemquest.com',
    'no-spam.ws',
    'nospam.ze.tc',
    'nospam4.us',
    'nospamfor.us',
    'nospammail.net',
    'no-spammers.com',
    'nospamthanks.info',
    'nostrajewellery.xyz',
    'nothingtoseehere.ca',
    'notif.me',
    'notmailinator.com',
    'notrnailinator.com',
    'notsharingmy.info',
    'no-ux.com',
    'now.im',
    'noway.pw',
    'nowhere.org',
    'nowmymail.com',
    'npv.kr',
    'nsaking.de',
    'ntlhelp.net',
    'ntub.cf',
    'nubescontrol.com',
    'nullbox.info',
    'nuo.kr',
    'nurfuerspam.de',
    'nus.edu.sg',
    'nut.cc',
    'nutpa.net',
    'nuts2trade.com',
    'nwldx.com',
    'ny7.me',
    'o.spamtrap.ro',
    'o060bgr3qg.com',
    'o2stk.org',
    'o7i.net',
    'oai.asia',
    'oalsp.com',
    'obfusko.com',
    'objectmail.com',
    'obo.kr',
    'obobbo.com',
    'obxpestcontrol.com',
    'oceancares.xyz',
    'odaymail.com',
    'odnorazovoe.ru',
    'oerpub.org',
    'offshore-proxies.net',
    'ohaaa.de',
    'ohdomain.xyz',
    'ohi.tw',
    'ohioticketpayments.xyz',
    'oing.cf',
    'okclprojects.com',
    'okrent.us',
    'okzk.com',
    'olypmall.ru',
    'omail.pro',
    'omnievents.org',
    'one.pl',
    'onebiginbox.com',
    'onelegalplan.com',
    'onemoremail.net',
    'oneoffemail.com',
    'oneoffmail.com',
    'one-time.email',
    'onewaymail.com',
    'onlatedotcom.info',
    'online.ms',
    'onlineidea.info',
    'onqin.com',
    'ontyne.biz',
    'oolus.com',
    'oopi.org',
    'opayq.com',
    'opendns.ro',
    'opentrash.com',
    'opmmedia.ga',
    'opp24.com',
    'orangotango.cf',
    'orangotango.ga',
    'orangotango.gq',
    'orangotango.ml',
    'orangotango.tk',
    'ordinaryamerican.net',
    'oreidresume.com',
    'org.ua',
    'orgmbx.cc',
    'oroki.de',
    'oshietechan.link',
    'otherinbox.codupmyspace.com',
    'otherinbox.com',
    'ourklips.com',
    'ourpreviewdomain.com',
    'outlawspam.com',
    'outlookpro.net',
    'ovpn.to',
    'ovvee.com',
    'owlpic.com',
    'ownsyou.de',
    'oxopoha.com',
    'oyu.kr',
    'ozyl.de',
    'p33.org',
    'p71ce1m.com',
    'pa9e.com',
    'pagamenti.tk',
    'pakadebu.ga',
    'paller.cf',
    'pancakemail.com',
    'paplease.com',
    'parkcrestlakewood.xyz',
    'parlimentpetitioner.tk',
    'partskyline.com',
    'pastebitch.com',
    'paulfucksallthebitches.com',
    'pavilionx2.com',
    'payperex2.com',
    'pc1520.com',
    'pcmylife.com',
    'pcusers.otherinbox.com',
    'pe.hu',
    'peapz.com',
    'pecdo.com',
    'pedimed-szczecin.pl',
    'pencalc.xyz',
    'penis.computer',
    'penisgoes.in',
    'pepbot.com',
    'peppe.usa.cc',
    'pepsi.coms.hk',
    'personal-email.ml',
    'peterdethier.com',
    'petrzilka.net',
    'pfui.ru',
    'photo-impact.eu',
    'photomark.net',
    'phpbb.uu.gl',
    'phus8kajuspa.cu.cc',
    'pi.vu',
    'pidmail.com',
    'pig.pp.ua',
    'pii.at',
    'piki.si',
    'pimpedupmyspace.com',
    'pinehill-seattle.org',
    'pingir.com',
    'pisls.com',
    'pjjkp.com',
    'plexolan.de',
    'plhk.ru',
    'ploae.com',
    'plw.me',
    'podam.pl',
    'poh.pp.ua',
    'pojok.ml',
    'pokemail.net',
    'pokiemobile.com',
    'polacy-dungannon.tk',
    'polarkingxx.ml',
    'politikerclub.de',
    'poliusraas.tk',
    'polyfaust.com',
    'pooae.com',
    'poofy.org',
    'pookmail.com',
    'poopiebutt.club',
    'pop3.xyz',
    'popesodomy.com',
    'popgx.com',
    'popmail.io',
    'popmailserv.org',
    'porco.cf',
    'porco.ga',
    'porco.gq',
    'porco.ml',
    'postacin.com',
    'postalmail.biz',
    'postonline.me',
    'poutineyourface.com',
    'powered.name',
    'powlearn.com',
    'poy.kr',
    'pp.ua',
    'ppetw.com',
    'predatorrat.cf',
    'predatorrat.ga',
    'predatorrat.gq',
    'predatorrat.ml',
    'predatorrat.tk',
    'premium-mail.fr',
    'premiumperson.website',
    'primabananen.net',
    'privacy.net',
    'privatdemail.net',
    'privy-mail.com',
    'privymail.de',
    'privy-mail.de',
    'procrackers.com',
    'projectcl.com',
    'project-xhabbo.com',
    'proprietativalcea.ro',
    'propscore.com',
    'pro-tag.org',
    'proxymail.eu',
    'proxyparking.com',
    'prs7.xyz',
    'prtnx.com',
    'prtz.eu',
    'psh.me',
    'psles.com',
    'psoxs.com',
    'psychedelicwarrior.xyz',
    'pterodactyl.email',
    'puglieisi.com',
    'puji.pro',
    'pulpmail.us',
    'pumps-fashion.com',
    'punkass.com',
    'purcell.email',
    'purelogistics.org',
    'put2.net',
    'puttanamaiala.tk',
    'putthisinyourspamdatabase.com',
    'pw.islam.igg.biz',
    'pwp.lv',
    'pwrby.com',
    'q314.net',
    'q5vm7pi9.com',
    'qafatwallet.com',
    'qasti.com',
    'qbfree.us',
    'qbi.kr',
    'qc.to',
    'qibl.at',
    'qipmail.net',
    'qiq.us',
    'qisdo.com',
    'qisoa.com',
    'qj97r73md7v5.com',
    'qoika.com',
    'qq.my',
    'qs2k.com',
    'qsl.ro',
    'qt1.ddns.net',
    'qtum-ico.com',
    'quadrafit.com',
    'querydirect.com',
    'quickinbox.com',
    'quickmail.nl',
    'quickreport.it',
    'qvap.ru',
    'qvy.me',
    'qwickmail.com',
    'r0.igg.biz',
    'r4nd0m.de',
    'r8r4p0cb.com',
    'ra3.us',
    'rabin.ca',
    'rabiot.reisen',
    'radecoratingltd.com',
    'raetp9.com',
    'rainmail.biz',
    'rainwaterstudios.org',
    'rajeshcon.cf',
    'raketenmann.de',
    'rancidhome.net',
    'randomail.net',
    'rao.kr',
    'raqid.com',
    'rarame.club',
    'rawhidefc.org',
    'rawmails.com',
    'rax.la',
    'raxtest.com',
    'rbb.org',
    'rcasd.com',
    'rcpt.at',
    'rcs7.xyz',
    'reality-concept.club',
    'reallymymail.com',
    'realtyalerts.ca',
    'receiveee.com',
    'recipeforfailure.com',
    'recode.me',
    'reconmail.com',
    'recursor.net',
    'recyclemail.dk',
    'redchan.it',
    'reddithub.com',
    'redfeathercrow.com',
    'redpeanut.com',
    'reftoken.net',
    'refurhost.com',
    'regbypass.com',
    'regbypass.comsafe-mail.net',
    're-gister.com',
    'regspaces.tk',
    'rejectmail.com',
    'rejo.technology',
    'reliable-mail.com',
    'remail.cf',
    'remail.ga',
    'remarkable.rocks',
    'remote.li',
    'renraku.in',
    'reptilegenetics.com',
    'resgedvgfed.tk',
    'resolution4print.info',
    'retkesbusz.nut.cc',
    'revolvingdoorhoax.org',
    'rfc822.org',
    'rgphotos.net',
    'rhombushorizons.com',
    'rhyta.com',
    'riamof.club',
    'ricrk.com',
    'riddermark.de',
    'risingsuntouch.com',
    'riski.cf',
    'rklips.com',
    'rko.kr',
    'rkomo.com',
    'rma.ec',
    'rmqkr.net',
    'rnailinator.com',
    'ro.lt',
    'robertspcrepair.com',
    'rollindo.agency',
    'ronnierage.net',
    'rooftest.net',
    'rootfest.net',
    'rotaniliam.com',
    'row.kr',
    'rowe-solutions.com',
    'royal.net',
    'royaldoodles.org',
    'rppkn.com',
    'rq6668f.com',
    'rr-0.cu.cc',
    'rr-1.cu.cc',
    'rr-2.cu.cc',
    'rr-3.cu.cc',
    'rrwbltw.xyz',
    'rtotlmail.net',
    'rtrtr.com',
    'rudymail.ml',
    'ruffrey.com',
    'ruggedinbox.com',
    'rumgel.com',
    'runi.ca',
    'ruru.be',
    'rustydoor.com',
    'ruu.kr',
    'rvb.ro',
    'rxtx.us',
    'ryanb.com',
    's.bungabunga.cf',
    's0ny.net',
    's33db0x.com',
    's51zdw001.com',
    'sabrestlouis.com',
    'sach.ir',
    'sackboii.com',
    'safaat.cf',
    'safe-mail.net',
    'safermail.info',
    'safersignup.com',
    'safersignup.de',
    'safetymail.info',
    'safetypost.de',
    'saharanightstempe.com',
    'salmeow.tk',
    'salonyfryzjerskie.info',
    'samsclass.info',
    'sandelf.de',
    'sandwhichvideo.com',
    'sanfinder.com',
    'sanim.net',
    'sanstr.com',
    'sapya.com',
    'sasa22.usa.cc',
    'sast.ro',
    'satukosong.com',
    'sausen.com',
    'savelife.ml',
    'saynotospams.com',
    'scatmail.com',
    'scay.net',
    'scbox.one.pl',
    'schachrol.com',
    'schafmail.de',
    'schmeissweg.tk',
    'schrott-email.de',
    'sd3.in',
    'sdf.org',
    'sdfghyj.tk',
    'searzh.com',
    'secmail.pw',
    'secretemail.de',
    'sector2.org',
    'secured-link.net',
    'securehost.com.es',
    'secure-mail.biz',
    'secure-mail.cc',
    'secure-mail.cn',
    'secureserver.usa.cc',
    'seekapps.com',
    'sejaa.lv',
    'selfdestructingmail.com',
    'selfdestructingmail.org',
    'semutkecil.com',
    'semut-kecil.com',
    'send-email.org',
    'sendfree.org',
    'sendingspecialflyers.com',
    'sendspamhere.com',
    'sendto.cf',
    'senseless-entertainment.com',
    'server.ms',
    'servermaps.net',
    'service4.ml',
    'services391.com',
    'sexforswingers.com',
    'sexical.com',
    'sexyalwasmi.top',
    'sezet.com',
    'sfmail.top',
    'shapoo.ch',
    'sharedmailbox.org',
    'sharklasers.com',
    'shhmail.com',
    'shhuut.org',
    'shieldedmail.com',
    'shieldemail.com',
    'shiftmail.com',
    'shipfromto.com',
    'shiphazmat.org',
    'shipping-regulations.com',
    'shippingterms.org',
    'shitaway.cf',
    'shitaway.ga',
    'shitaway.gq',
    'shitaway.ml',
    'shitaway.tk',
    'shitmail.de',
    'shitmail.me',
    'shitmail.org',
    'shitposting.agency',
    'shitware.nl',
    'shmeriously.com',
    'shockinmytown.cu.cc',
    'shonky.info',
    'shortmail.net',
    'shotmail.ru',
    'showme.social',
    'showslow.de',
    'shrib.com',
    'shuffle.email',
    'shurs.xyz',
    'shut.name',
    'shut.ws',
    'sibmail.com',
    'sify.com',
    'sikux.com',
    'siliwangi.ga',
    'simpleitsecurity.info',
    'simscity.cf',
    'sin.cl',
    'sina.com',
    'sinda.club',
    'sinfiltro.cl',
    'singlespride.com',
    'sinnlos-mail.de',
    'sino.tw',
    'siteposter.net',
    'sizzlemctwizzle.com',
    'sjuaq.com',
    'skeefmail.com',
    'skrx.tk',
    'sky-inbox.com',
    'sky-mail.ga',
    'sky-ts.de',
    'slapsfromlastnight.com',
    'slaskpost.se',
    'slave-auctions.net',
    'slippery.email',
    'slipry.net',
    'slopsbox.com',
    'slothmail.net',
    'slowfoodfoothills.xyz',
    'slowslow.de',
    'slsrs.ru',
    'slu21svky.com',
    'slushmail.com',
    'slutty.horse',
    'sly.io',
    'smallker.tk',
    'smapfree24.com',
    'smapfree24.de',
    'smapfree24.eu',
    'smapfree24.info',
    'smapfree24.org',
    'smashmail.de',
    'smellfear.com',
    'smellrear.com',
    'smellypotato.tk',
    'smoug.net',
    'smsforum.ro',
    'smtp99.com',
    'smwg.info',
    'snakemail.com',
    'sneakemail.com',
    'sneakmail.de',
    'snkmail.com',
    'socialfurry.org',
    'social-mailer.tk',
    'sofimail.com',
    'sofortmail.de',
    'sofort-mail.de',
    'softpls.asia',
    'sogetthis.com',
    'sohu.com',
    'sohu.net',
    'soisz.com',
    'solar-impact.pro',
    'solvemail.info',
    'solventtrap.wiki',
    'sonshi.cf',
    'soodmail.com',
    'soodomail.com',
    'soodonims.com',
    'soon.it',
    'sosmanga.com',
    'spa.com',
    'spaereplease.com',
    'spam.flu.cc',
    'spam.igg.biz',
    'spam.la',
    'spam.nut.cc',
    'spam.org.es',
    'spam.su',
    'spam.usa.cc',
    'spam4.me',
    'spamail.de',
    'spamarrest.com',
    'spamavert.com',
    'spam-be-gone.com',
    'spambob.com',
    'spambob.net',
    'spambob.org',
    'spambog.com',
    'spambog.de',
    'spambog.net',
    'spambog.ru',
    'spambooger.com',
    'spambox.info',
    'spambox.irishspringrealty.com',
    'spambox.org',
    'spambox.us',
    'spamcannon.com',
    'spamcannon.net',
    'spamcero.com',
    'spamcon.org',
    'spamcorptastic.com',
    'spamcowboy.com',
    'spamcowboy.net',
    'spamcowboy.org',
    'spamday.com',
    'spamdecoy.net',
    'spamex.com',
    'spamfighter.cf',
    'spamfighter.ga',
    'spamfighter.gq',
    'spamfighter.ml',
    'spamfighter.tk',
    'spamfree.eu',
    'spamfree24.com',
    'spamfree24.de',
    'spamfree24.eu',
    'spamfree24.info',
    'spamfree24.net',
    'spamfree24.org',
    'spamgoes.in',
    'spamgourmet.com',
    'spamgourmet.net',
    'spamgourmet.org',
    'spamherelots.com',
    'spamhereplease.com',
    'spamhole.com',
    'spamify.com',
    'spaminator.de',
    'spamkill.info',
    'spaml.com',
    'spaml.de',
    'spamlot.net',
    'spammedic.com',
    'spammotel.com',
    'spamobox.com',
    'spamoff.de',
    'spamsalad.in',
    'spamserver.cf',
    'spamserver.ml',
    'spamserver.tk',
    'spamslicer.com',
    'spamspot.com',
    'spamstack.net',
    'spamthis.co.uk',
    'spamthisplease.com',
    'spamtrail.com',
    'spamtrap.co',
    'spamtrap.ro',
    'spamtroll.net',
    'spamwc.de',
    'spamwc.ga',
    'spb.ru',
    'speed.1s.fr',
    'speedgaus.net',
    'sperma.cf',
    'spikio.com',
    'spoofmail.de',
    'spr.io',
    'spritzzone.de',
    'spybox.de',
    'squizzy.de',
    'squizzy.eu',
    'squizzy.net',
    'sraka.xyz',
    'sroff.com',
    'sry.li',
    's-s.flu.cc',
    'ssgjylc1013.com',
    'ssoia.com',
    'stanfordujjain.com',
    'starlight-breaker.net',
    'startfu.com',
    'startkeys.com',
    'statdvr.com',
    'stathost.net',
    'statiix.com',
    'stealthypost.org',
    'steambot.net',
    'steamprank.com',
    'stexsy.com',
    'stg.malibucoding.com',
    'stinkefinger.net',
    'stophabbos.tk',
    'stop-my-spam.cf',
    'stop-my-spam.com',
    'stop-my-spam.ga',
    'stop-my-spam.ml',
    'stop-my-spam.pp.ua',
    'stop-my-spam.tk',
    'storj99.com',
    'storj99.top',
    'stpetersandstpauls.xyz',
    'streamfly.biz',
    'streamfly.link',
    'streetwisemail.com',
    'stromox.com',
    'stuckmail.com',
    'studiopolka.tokyo',
    'stuffmail.de',
    'stumpfwerk.com',
    'suburbanthug.com',
    'suckmyd.com',
    'sucknfuck.site',
    'sudolife.me',
    'sudolife.net',
    'sudomail.biz',
    'sudomail.com',
    'sudomail.net',
    'sudoverse.com',
    'sudoverse.net',
    'sudoweb.net',
    'sudoworld.com',
    'sudoworld.net',
    'suioe.com',
    'super-auswahl.de',
    'supergreatmail.com',
    'supermailer.jp',
    'superplatyna.com',
    'superrito.com',
    'superstachel.de',
    'suremail.info',
    'surveyrnonkey.net',
    'susi.ml',
    'sute.jp',
    'svk.jp',
    'svxr.org',
    'sweetpotato.ml',
    'sweetxxx.de',
    'swift10minutemail.com',
    'sxylc113.com',
    'sylvannet.com',
    'symphonyresume.com',
    'syujob.accountants',
    'szerz.com',
    'szucsati.net',
    't24e4p7.com',
    't3t97d1d.com',
    'tafmail.com',
    'tafoi.gr',
    'taglead.com',
    'tagmymedia.com',
    'tagyourself.com',
    'takedowns.org',
    'talkinator.com',
    'tanukis.org',
    'taosjw.com',
    'tapchicuoihoi.com',
    'tarzanmail.cf',
    'tarzanmail.ml',
    'taskforcetech.com',
    'tb-on-line.net',
    'tdf-illustration.com',
    'teamspeak3.ga',
    'techemail.com',
    'techgroup.me',
    'techmail.info',
    'teerest.com',
    'teewars.org',
    'tefl.ro',
    'telecomix.pl',
    'teleosaurs.xyz',
    'teleworm.com',
    'teleworm.us',
    'tellos.xyz',
    'temp.emeraldwebmail.com',
    'temp.headstrong.de',
    'temp1.club',
    'temp15qm.com',
    'temp2.club',
    'tempail.com',
    'tempalias.com',
    'tempemail.biz',
    'tempemail.co.za',
    'tempemail.com',
    'tempe-mail.com',
    'tempemail.net',
    'tempemail.org',
    'tempemails.io',
    'tempinbox.co.uk',
    'tempinbox.com',
    'tempmail.co',
    'temp-mail.com',
    'tempmail.de',
    'temp-mail.de',
    'tempmail.eu',
    'tempmail.it',
    'temp-mail.net',
    'temp-mail.org',
    'tempmail.pro',
    'temp-mail.ru',
    'tempmail.space',
    'tempmail.us',
    'tempmail2.com',
    'tempmaildemo.com',
    'tempmailer.com',
    'tempmailer.de',
    'tempomail.fr',
    'temporamail.com',
    'temporarily.de',
    'temporarioemail.com.br',
    'temporaryemail.net',
    'temporaryemail.us',
    'temporaryforwarding.com',
    'temporaryinbox.com',
    'temporarymailaddress.com',
    'tempsky.com',
    'tempthe.net',
    'tempymail.com',
    'tinoza.org',
    'ternaklele.ga',
    'test.com',
    'test.de',
    'testudine.com',
    'tfwno.gf',
    'thanksnospam.info',
    'thankyou2010.com',
    'thc.st',
    'theaperturelabs.com',
    'theaperturescience.com',
    'theaviors.com',
    'thebearshark.com',
    'thebest4ever.com',
    'thecloudindex.com',
    'thediamants.org',
    'thelightningmail.net',
    'thelimestones.com',
    'thembones.com.au',
    'themostemail.com',
    'theopposition.club',
    'theplug.org',
    'thereddoors.online',
    'thescrappermovie.com',
    'thespawningpool.com',
    'theteastory.info',
    'thetrash.email',
    'thex.ro',
    'thietbivanphong.asia',
    'thisisnotmyrealemail.com',
    'thismail.net',
    'thisurl.website',
    'thnikka.com',
    'thraml.com',
    'thrma.com',
    'throam.com',
    'thrott.com',
    'throwam.com',
    'throwawayemail.com',
    'throwawayemailaddress.com',
    'throwawaymail.com',
    'throya.com',
    'thunkinator.org',
    'thxmate.com',
    'tic.ec',
    'ticket-please.ga',
    'tijdelijkmailadres.nl',
    'tilien.com',
    'timekr.xyz',
    'timgiarevn.com',
    'timkassouf.com',
    'tinyurl24.com',
    'tipsb.com',
    'tittbit.in',
    'tiv.cc',
    'tizi.com',
    'tkitc.de',
    'tkmy88m.com',
    'tko.kr',
    'tlpn.org',
    'tm.slsrs.ru',
    'tmail.com',
    'tmail.ws',
    'tmailinator.com',
    'tmails.net',
    'tmo.kr',
    'tmpeml.info',
    'tmpjr.me',
    'tmpmail.net',
    'tntitans.club',
    'toddsbighug.com',
    'toi.kr',
    'toiea.com',
    'tokem.co',
    'tokenmail.de',
    'tokuriders.club',
    'tom.com',
    'tonymanso.com',
    'tool.pp.ua',
    'toomail.biz',
    'toon.ml',
    'toothandmail.com',
    'top101.de',
    'top1mail.ru',
    'top1post.ru',
    'top9appz.info',
    'topinrock.cf',
    'topmall.com',
    'topmall.info',
    'topmall.org',
    'topofertasdehoy.com',
    'topranklist.de',
    'toprumours.com',
    'tormail.net',
    'tormail.org',
    'toss.pw',
    'tosunkaya.com',
    'totalvista.com',
    'totesmail.com',
    'tp-qa-mail.com',
    'tqoai.com',
    'tqosi.com',
    'tracciabi.li',
    'tradermail.info',
    'tralalajos.ga',
    'tralalajos.gq',
    'tralalajos.ml',
    'tralalajos.tk',
    'tranceversal.com',
    'trash2009.com',
    'trash2010.com',
    'trash2011.com',
    'trash247.com',
    'trash4.me',
    'trash-amil.com',
    'trashcanmail.com',
    'trashdevil.com',
    'trashdevil.de',
    'trashemail.de',
    'trashemails.de',
    'trashinbox.com',
    'trashmail.at',
    'trash-mail.at',
    'trash-mail.cf',
    'trashmail.com',
    'trash-mail.com',
    'trashmail.de',
    'trash-mail.de',
    'trash-mail.ga',
    'trash-mail.gq',
    'trashmail.io',
    'trashmail.me',
    'trash-mail.ml',
    'trashmail.net',
    'trash-mail.net',
    'trashmail.org',
    'trash-mail.tk',
    'trashmail.ws',
    'trashmailer.com',
    'trash-me.com',
    'trashymail.com',
    'trashymail.net',
    'trasz.com',
    'trayna.com',
    'trbvm.com',
    'trbvn.com',
    'trbvo.com',
    'trebusinde.cf',
    'trebusinde.ml',
    'trendingtopic.cl',
    'trialmail.de',
    'trickmail.net',
    'trillianpro.com',
    'trollproject.com',
    'tropicalbass.info',
    'trungtamtoeic.com',
    'tryalert.com',
    'ts-by-tashkent.cf',
    'ts-by-tashkent.ga',
    'ts-by-tashkent.gq',
    'ts-by-tashkent.ml',
    'ts-by-tashkent.tk',
    'ttszuo.xyz',
    'tualias.com',
    'tucumcaritonite.com',
    'tug.minecraftrabbithole.com',
    'turoid.com',
    'turual.com',
    'tvchd.com',
    'tverya.com',
    'tweakly.net',
    'twinmail.de',
    'twkly.ml',
    'twoweirdtricks.com',
    'txt7e99.com',
    'txtadvertise.com',
    'tyhe.ro',
    'tyldd.com',
    'u14269.gq',
    'u14269.ml',
    'u6lvty2.com',
    'ua3jx7n0w3.com',
    'uacro.com',
    'ubismail.net',
    'ubm.md',
    'ucche.us',
    'ucupdong.ml',
    'ucylu.com',
    'ufacturing.com',
    'ufgqgrid.xyz',
    'uggsrock.com',
    'uguuchantele.com',
    'uha.kr',
    'uhhu.ru',
    'uikd.com',
    'ujijima1129.gq',
    'uk.to',
    'ukexample.com',
    'uko.kr',
    'umail.net',
    'umy.kr',
    'undo.it',
    'unids.com',
    'unimark.org',
    'unit7lahaina.com',
    'unlimit.com',
    'unmail.ru',
    'uny.kr',
    'upliftnow.com',
    'uplipht.com',
    'uploadnolimit.com',
    'upy.kr',
    'urbanchickencoop.com',
    'urfey.com',
    'urfunktion.se',
    'uroid.com',
    'us.af',
    'us.to',
    'usa.cc',
    'uscaves.com',
    'used-product.fr',
    'username.e4ward.com',
    'ushijima1129.cf',
    'ushijima1129.ga',
    'ushijima1129.gq',
    'ushijima1129.ml',
    'ushijima1129.tk',
    'utiket.us',
    'uu.gl',
    'uvy.kr',
    'uwork4.us',
    'uyhip.com',
    'uyu.kr',
    'uz6tgwk.com',
    'vaasfc4.tk',
    'vaati.org',
    'vaffanculo.gq',
    'valemail.net',
    'valhalladev.com',
    'vanacken.xyz',
    'vankin.de',
    'vba.kr',
    'vctel.com',
    'vda.ro',
    'vdig.com',
    'vektik.com',
    'venompen.com',
    'verdejo.com',
    'vermutlich.net',
    'veryday.ch',
    'veryday.eu',
    'veryday.info',
    'veryrealemail.com',
    'vesa.pw',
    'vfemail.net',
    'vickaentb.cf',
    'vickaentb.ga',
    'vickaentb.gq',
    'vickaentb.ml',
    'vickaentb.tk',
    'victime.ninja',
    'victoriantwins.com',
    'vidchart.com',
    'viditag.com',
    'viewcastmedia.com',
    'viewcastmedia.net',
    'viewcastmedia.org',
    'viewcastmediae',
    'vihost.ml',
    'vihost.tk',
    'vikingsonly.com',
    'vinernet.com',
    'vipmail.name',
    'vipmail.pw',
    'vip-mail.tk',
    'vipsohu.net',
    'vipxm.net',
    'viralplays.com',
    'viroleni.cu.cc',
    'visa.coms.hk',
    'vistomail.com',
    'vixletdev.com',
    'vkcode.ru',
    'vmail.me',
    'vmailing.info',
    'vmani.com',
    'vmpanda.com',
    'vncoders.net',
    'vnedu.me',
    'voidbay.com',
    'vomoto.com',
    'vorga.org',
    'votiputox.org',
    'voxelcore.com',
    'vpn.st',
    'vpn33.top',
    'vps30.com',
    'vps911.net',
    'vpsorg.pro',
    'vpsorg.top',
    'vpstraffic.com',
    'vrmtr.com',
    'vrsim.ir',
    'vs904a6.com',
    'vsimcard.com',
    'vssms.com',
    'vtxmail.us',
    'vubby.com',
    'vuiy.pw',
    'vzlom4ik.tk',
    'vztc.com',
    'w22fe21.com',
    'w3internet.co.uk',
    'w4i3em6r.com',
    'w918bsq.com',
    'w9f.de',
    'w9y9640c.com',
    'wakingupesther.com',
    'walala.org',
    'walkmail.net',
    'walkmail.ru',
    'wallm.com',
    'warau-kadoni.com',
    'wasd.10mail.org',
    'wasdfgh.cf',
    'wasdfgh.ga',
    'wasdfgh.gq',
    'wasdfgh.ml',
    'wasdfgh.tk',
    'wasteland.rfc822.org',
    'watchever.biz',
    'watchfull.net',
    'watch-harry-potter.com',
    'watchironman3onlinefreefullmovie.com',
    'wawi.es',
    'wazabi.club',
    'wbml.net',
    'web.id',
    'webarnak.fr.eu.org',
    'web-contact.info',
    'webcontact-france.eu',
    'webemail.me',
    'web-emailbox.eu',
    'web-ideal.fr',
    'webm4il.info',
    'web-mail.pp.ua',
    'webmeetme.com',
    'webtrip.ch',
    'webuser.in',
    'wee.my',
    'wef.gr',
    'wefjo.grn.cc',
    'wegas.ru',
    'wegwerfadresse.de',
    'wegwerf-email.at',
    'wegwerfemail.com',
    'wegwerfemail.de',
    'weg-werf-email.de',
    'wegwerf-email.de',
    'wegwerfemail.info',
    'wegwerfemail.net',
    'wegwerf-email.net',
    'wegwerfemail.org',
    'wegwerf-email-addressen.de',
    'wegwerfemailadresse.com',
    'wegwerf-email-adressen.de',
    'wegwerf-emails.de',
    'wegwerfmail.de',
    'wegwerfmail.info',
    'wegwerfmail.net',
    'wegwerfmail.org',
    'wegwerpmailadres.nl',
    'wegwrfmail.de',
    'wegwrfmail.net',
    'wegwrfmail.org',
    'welikecookies.com',
    'wem.com',
    'wetrainbayarea.com',
    'wetrainbayarea.org',
    'wfgdfhj.tk',
    'wg0.com',
    'wh4f.org',
    'whatiaas.com',
    'whatifanalytics.com',
    'whatpaas.com',
    'whatsaas.com',
    'whiffles.org',
    'whopy.com',
    'whtjddn.33mail.com',
    'whyspam.me',
    'wibblesmith.com',
    'wickmail.net',
    'widget.gg',
    'wierie.tk',
    'wiki.8191.at',
    'wil.kr',
    'wilemail.com',
    'willhackforfood.biz',
    'willselfdestruct.com',
    'wimsg.com',
    'winemaven.info',
    'wins.com.br',
    'wishan.net',
    'wiz2.site',
    'wlist.ro',
    'wmail.cf',
    'wmail.club',
    'wolfmission.com',
    'wolfsmail.ml',
    'wolfsmail.tk',
    'wolfsmails.tk',
    'wollan.info',
    'worldspace.link',
    'wormseo.cn',
    'wpg.im',
    'wr9v6at7.com',
    'wralawfirm.com',
    'writeme.com',
    'writeme.us',
    'wronghead.com',
    'ws.gy',
    'wudet.men',
    'wupics.com',
    'wuzup.net',
    'wuzupmail.net',
    'www.e4ward.com',
    'www.gishpuppy.com',
    'www.mailinator.com',
    'www.redpeanut.com',
    'wwwnew.eu',
    'wxnw.net',
    'wyvernia.net',
    'wzukltd.com',
    'x.ip6.li',
    'x1x.spb.ru',
    'x1x22716.com',
    'x24.com',
    'x4y.club',
    'x5a9m8ugq.com',
    'x8h8x941l.com',
    'xagloo.co',
    'xagloo.com',
    'xcode.ro',
    'xcompress.com',
    'xcpy.com',
    'xemaps.com',
    'xents.com',
    'xing886.uu.gl',
    'xjoi.com',
    'xl.cx',
    'xlgaokao.com',
    'xmail.com',
    'xmaily.com',
    'xn--9kq967o.com',
    'xost.us',
    'xoxox.cc',
    'xoxy.net',
    'xperiae5.com',
    'xrho.com',
    'xwaretech.com',
    'xwaretech.info',
    'xwaretech.net',
    'xww.ro',
    'xx-9.tk',
    'xxhamsterxx.ga',
    'xxlocanto.us',
    'xxolocanto.us',
    'xxqx3802.com',
    'xy9ce.tk',
    'xyzfree.net',
    'xzsok.com',
    'y.bcb.ro',
    'y59.jp',
    'ya.yomail.info',
    'yadavnaresh.com.np',
    'yandere.cu.cc',
    'yanet.me',
    'yapped.net',
    'yaqp.com',
    'yasser.ru',
    'ycare.de',
    'ycn.ro',
    'ye.vc',
    'yeah.net',
    'yedi.org',
    'yentzscholarship.xyz',
    'yep.it',
    'ygroupvideoarchive.com',
    'ygroupvideoarchive.net',
    'yhg.biz',
    'ymail.net',
    'ymail.org',
    'ynmrealty.com',
    'yodx.ro',
    'yogamaven.com',
    'yomail.info',
    'yoo.ro',
    'yop.ze.cx',
    'yopmail.com',
    'yopmail.fr',
    'yopmail.gq',
    'yopmail.info',
    'yopmail.net',
    'yopmail.org',
    'yopmail.pp.ua',
    'yordanmail.cf',
    'yoru-dea.com',
    'you.e4ward.com',
    'youcankeepit.info',
    'yougotgoated.com',
    'youmail.ga',
    'youmailr.com',
    'youneedmore.info',
    'youporn.flu.cc',
    'youporn.igg.biz',
    'youporn.usa.cc',
    'yourdomain.com',
    'yourewronghereswhy.com',
    'yourlms.biz',
    'yourtube.ml',
    'you-spam.com',
    'ypmail.webarnak.fr.eu.org',
    'yroid.com',
    'yspend.com',
    'yugasandrika.com',
    'yui.it',
    'yuurok.com',
    'yxzx.net',
    'yy-h2.nut.cc',
    'yyhmail.com',
    'yyj295r31.com',
    'yyolf.net',
    'yytv.ddns.net',
    'z0d.eu',
    'z1p.biz',
    'z7az14m.com',
    'z86.ru',
    'za.com',
    'zain.site',
    'zainmax.net',
    'zaktouni.fr',
    'zane.rocks',
    'zasod.com',
    'zasve.info',
    'zavio.nl',
    'ze.cx',
    'ze.tc',
    'zebins.com',
    'zebins.eu',
    'zehnminuten.de',
    'zehnminutenmail.de',
    'zepp.dk',
    'zeta-telecom.com',
    'zetmail.com',
    'zfymail.com',
    'zhcne.com',
    'zhorachu.com',
    'zhouemail.510520.org',
    'zik.dj',
    'zippymail.info',
    'zipsendtest.com',
    'zipzaps.de',
    'zmail.info.tm',
    'zoaxe.com',
    'zoemail.com',
    'zoemail.net',
    'zoemail.org',
    'zoetropes.org',
    'zombie-hive.com',
    'zomg.info',
    'zp.ua',
    'zumpul.com',
    'zxcv.com',
    'zxcvbnm.com',
    'zxcxc.com',
    'zzi.us',
    'zzz.com']

export default domains
